import { Vue, Component, Prop } from 'vue-property-decorator';
import { IdentificationRequestStatus } from '@/store/models/identificationRequest';

@Component
export default class IdentificationIdin extends Vue {
  @Prop({ default: IdentificationRequestStatus.Approved })
  identificationStatus!: IdentificationRequestStatus;

  get redirectTo(): string | null {
    return this.$route.query.redirectTo as string || null;
  }
}

import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import { getNames, LocalizedCountryNames } from 'i18n-iso-countries';
import { Action, State as StateClass } from 'vuex-class';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Loader from '@/components/common/loader/Loader.vue';
import { State } from '@/store/models';
import { UserGender, BusinessIdentification } from '@/store/models/identificationRequest';
import { IdentRouteNames } from '@/modules/identification/identRouteNames';
import FormInput from '@/components/common/form-elements/FormInput.vue';
import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
import FormSelect from '@/components/common/form-elements/FormSelect.vue';
import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';
import FormFileInput from '@/components/common/form-elements/FormFileInput.vue';

@Component({
  components: {
    Loader,
    ValidationObserver,
    ValidationProvider,
    FormInput,
    FormDatePicker,
    FormSelect,
    FormInvalidMessage,
    FormFileInput,
  },
})
export default class IdentificationBusiness extends Vue {
  name: string = '';
  surname: string = '';
  telephone: string = '';
  dateOfBirth: Date | null = null;
  bankAccount: string = '';
  gender: UserGender | null = null;
  nationality: string = 'NL';
  streetAddress: string = '';
  houseNumber: string = '';
  city: string = '';
  country: string = 'NL';
  postalCode: string = '';
  passport: File | null = null;
  kvkImage: File | null = null;
  companyId: string = '';
  kvk: number | null = null;

  @Action sendBusinessIdentification!: Function;
  @Action(addToastMessage) addToastMessage!: Function;

  @StateClass operations!: State['operations'];

  @Ref() readonly passportUpload!: HTMLInputElement;
  @Ref('form') readonly form!: InstanceType<typeof ValidationObserver>;

  @Watch('operations')
  onOperationsChange(newOperation: any, oldOperation: any): void {
    if (newOperation.name === 'SEND_BUSINESS_IDENTIFICATION_ERROR'
       && oldOperation.name === 'SEND_BUSINESS_IDENTIFICATION_PROCESSING') {
      this.addToastMessage({
        text: newOperation.error,
        type: 'danger',
      });
    }
  }

  /**
   * Returns list of countries.
   */
  get countries(): LocalizedCountryNames {
    const lang: string = this.$i18n.locale;
    return getNames(lang);
  }

  get genderOptions(): { text: string, value: UserGender }[] {
    return [
      { text: this.$t('common.male') as string, value: UserGender.Male },
      { text: this.$t('common.female') as string, value: UserGender.Female },
    ];
  }

  get businessProcessing(): boolean {
    return this.operations.name === 'SEND_BUSINESS_IDENTIFICATION_PROCESSING';
  }

  get redirect(): any {
    return this.$route.query.redirect;
  }

  get from(): any {
    return this.$route.query.from;
  }

  async submitBusinessCheckout(): Promise<void> {
    if (await this.form.validate()) {
      const businessIdentRequest: BusinessIdentification = {
        name: this.name,
        surname: this.surname,
        telephone: this.telephone,
        streetAddress: this.streetAddress,
        houseNumber: this.houseNumber,
        bankAccount: this.bankAccount,
        gender: this.gender!,
        // the form would not be valid if there's no dateOfBirth
        // sending as number since its a Cloud Function the handler
        // @ts-ignore
        dateOfBirth: Date.UTC(this.dateOfBirth!.getFullYear(), this.dateOfBirth!.getMonth(), this.dateOfBirth!.getDate()),
        city: this.city,
        country: this.country,
        nationality: this.nationality,
        postalCode: this.postalCode,
        companyId: this.companyId,
        kvk: this.kvk!,
        // @ts-ignore (it's transformed in the sendBusinessIdentification action)
        passport: this.passport,
        // @ts-ignore (it's transformed in the sendBusinessIdentification action)
        kvkImage: this.kvkImage,
      };
      this.sendBusinessIdentification(businessIdentRequest);
      return;
    }
    this.addToastMessage({
      text: 'There are missing fields or they are incorrectly submitted.',
      type: 'danger',
    });
  }

  onFileChange(event: any, which: 'passport' | 'kvk'): void {
    const customFile = event.target?.files[0] || event.dataTransfer?.files[0];
    if (!customFile) {
      return;
    }

    if (which === 'passport') {
      this.passport = customFile;
    } else {
      this.kvkImage = customFile;
    }
  }

  goTo(): void {
    const query: { [key: string]: any } = {};
    if (this.redirect) {
      query.redirect = this.redirect;
    }
    if (this.from) {
      query.from = this.from;
    }
    this.$router.push({
      name: IdentRouteNames.MAIN,
      query,
    }).catch((): void => {});
  }
}
